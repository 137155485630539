#main-nav {
    width: 100%;
    height: $navHeight;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 5px;
    background: yellow;
    h1 {
        margin: 0;
    }
}
