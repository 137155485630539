@mixin imgResponsive($maxWidth: 100%) {
    width: 100%;
    height: auto;
    max-width: $maxWidth;
}

@mixin placeholderThumbnail() {
    position: relative;
    &:before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 100%;
    }
    img {
        position: absolute;
        left: 0;
        top: 0;
        @include imgResponsive();
    }
}
