@mixin fadeUp($dur: 500ms) {
    opacity: 0;
    animation: fadeUp $dur ease;
    animation-fill-mode: forwards;
}

.animation--fadeUp {
    animation: fadeUp 500ms ease;
    animation-fill-mode: forwards;
}

@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
