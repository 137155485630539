#animatedThumbnail {
    position: absolute;
    left: -9999px;
    &.active {
        transition: all 1s cubic-bezier(0.6, -0.28, 0.735, 0.045); 
    }
    img {
        width: 100%;
        height: auto;
    }
}
