.list {
    display: flex;
    padding: 0;
    flex-flow: row wrap;

    &--pad {
        padding:0 $gutters/2;
    }

    &__thumbnail__inner {
        padding: 10px;
        border-radius: 8px;
    }

    &__thumbnail {
        list-style: none;
        width: 50%;
        padding: 10px;
        @include fadeUp();
        @include mobile {
            width:25%;
        }
        @include desktop {
            width: 20%;
        }
        &--link {
            a {
                text-decoration: none;
            }
        }
    }

    &__thumbnail__caption {
        padding-top: 10px;
        text-align: center;
        text-transform: capitalize;
    }
}
