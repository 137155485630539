* {
    box-sizing: border-box;;
}

body {
    padding: 0;
    margin: 0;
    font-family: sans-serif;
    background: #fff3c7;
}

#app {
    padding-top: $navHeight;
}

.col-container {
    display: flex;
    flex-flow: row wrap;
}

.col-2-tablet {
    width: 100%;
    @include tablet {
        width: 50%;
        padding: 0 5px;
    }
}
